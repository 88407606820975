<template>
  <div>
    <v-dialog
      v-model="dialog"
      class="modal__size"
      :max-width="maxWidth"
      :persistent="true"
    >
      <v-card class="">
        <v-card-title
          class="d-flex justify-space-between align-center pl-6 pr-4 py-2"
        >
          <div class="modal__title">
            {{ title }}
          </div>
          <v-btn
            icon="mdi-close"
            variant="text"
            size="x-small"
            :disabled="masters.globalLoading"
            @click="cancelClick"
          ></v-btn>
        </v-card-title>
        <v-divider color="dark-darken-9"></v-divider>

        <v-card-text class="pa-2"> <slot></slot> </v-card-text>

        <v-card-actions class="px-6 pt-2 pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="text-none"
            text
            rounded="1"
            @click="cancelClick"
            :disabled="masters.globalLoading"
          >
            {{
              confirm
                ? $t("common.buttons.cancel")
                : $t("common.buttons.accept")
            }}</v-btn
          >
          <v-btn
            v-if="confirm"
            class="text-none ms-4 text-white"
            color="brand"
            variant="flat"
            rounded="1"
            @click="confirmClick"
            :disabled="masters.globalLoading"
            :loading="masters.globalLoading"
            >{{ $t("common.buttons." + btnConfirm) }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
    
<script setup>
import { useMasters } from "~/store/masters";

const masters = useMasters();

const props = defineProps({
  title: {
    type: String,
    default: null,
  },
  btnConfirm: {
    type: String,
    default: null,
  },
  dialog: {
    type: Boolean,
    required: true,
    default: false,
  },
  confirm: {
    type: Boolean,
    required: true,
    default: false,
  },
  maxWidth: {
    type: Number,
    default: 720,
  },
});

let dialog = ref(false);

watch(
  () => props.dialog,
  (first, second) => {
    dialog = ref(first);
  }
);

const emit = defineEmits(["cancel-click", "confirm-click"]);

const cancelClick = () => {
  emit("cancel-click");
};
const confirmClick = () => {
  emit("confirm-click");
};
</script>
    
<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
.modal {
  &__title {
    font-size: $font-size-l;
    font-weight: $font-weight-bold;

    &--border {
      border-bottom: 1px $color-dark-900 solid;
      color: $color-brand-500;
    }
  }
}
</style>
    